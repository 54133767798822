.about {
  margin-top: unset;
  width: 100%;
  padding-top: 6rem;
}

.about .about-wrapper .content-header {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.about-wrapper .content-header h1 {
  font-weight: 400;
  font-size: 32px;
  line-height: 114.5%;
  text-align: center;
  color: #2c3739;
  max-width: 451px;
}

.about-wrapper .about-banner img {
  width: 100%;
  transform: skewY(-1deg);
}

@media screen and (max-width: 767px) {
  .about-wrapper .content-header h1 {
    font-size: 1.75rem;
  }
}

.training-tutorial-wrapper .content-header h1 {
  font-weight: 400;
  font-size: 32px;
  line-height: 102.5%;
  color: #2c3739;
  margin-top: 80px;
}

.training-tutorial-wrapper .card-group {
  gap: 1rem;
  margin-top: 2rem;
}

.training-tutorial-wrapper .card-group .card {
  background: #ffffff;
  border: 0.5px solid #c5c5c5;
  border-radius: 2px;
}

.training-tutorial-wrapper .card-group .card .card-body {
  padding: 2rem 2rem;
  text-align: start;
}

.training-tutorial-wrapper .card-group .card .card-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #2c3739;
  margin-bottom: unset;
}

.training-tutorial-wrapper .card-group .card .card-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #535353;
  margin-top: unset;
}

.training-tutorial-wrapper .btn-see-all {
  background: #fff1e8;
  height: 43px;
  width: 184px;
  border: none;
  border-radius: 2px;

  font-weight: 600;
  font-size: 16px;
  line-height: 114.5%;
  text-align: center;
  color: #4d61ac;
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  .training-tutorial-wrapper .content-header h1 {
    font-size: 1.75rem;
  }

  .training-tutorial-wrapper .card-group .card .card-title {
    font-size: 1.25rem;
  }

  .training-tutorial-wrapper .card-group .card .card-text {
    font-size: 0.875rem;
  }
}

.usecase .usecase-wrapper {
  display: flex;
  justify-content: space-between;
}

.usecase-wrapper .content-left {
}

.usecase-wrapper .content-left span {
  font-weight: 500;
  font-size: 16px;
  line-height: 114.5%;
  text-transform: uppercase;
  color: #45035a;
}

.usecase-wrapper .content-left .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 114.5%;
  color: #2c3739;
}

.usecase-wrapper .content-left .subheading {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #535353;
  margin-bottom: 50px;
}

.usecase-wrapper .content-left .btn-contact {
  width: 186px;
  height: 40px;
  background: #4d61ac;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}

.usecase-wrapper .content-right .menu {
  display: flex;
  gap: 20px;
}

.usecase-wrapper .content-right .menu .menu-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 114.5%;
  color: #535353;
  cursor: pointer;
}

.usecase-wrapper .content-right .menu .menu-item.active {
  color: #4d61ac;
}

.usecase-wrapper .content-right .border {
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 991px) {
  .usecase .usecase-wrapper {
    display: block;
  }
  .usecase-wrapper .content-right {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .usecase-wrapper .content-left span {
    font-size: 0.875rem;
  }

  .usecase-wrapper .content-left .heading {
    font-size: 1.75rem;
  }

  .usecase-wrapper .content-left .subheading {
    font-size: 0.875rem;
  }

  .usecase-wrapper .content-left .btn-contact {
    font-size: 0.875rem;
  }

  .usecase-wrapper .content-right .menu .menu-item {
    font-size: 0.875rem;
  }
}

.implementation .implementation-wrapper {
  margin: auto;
  padding: 0 50px;
  margin-top: 100px;
}

.implementation-wrapper .content-header {
  display: flex;
  justify-content: center;
  text-align: center;
}

.implementation-wrapper .content-header span {
  font-weight: 500;
  font-size: 16px;
  line-height: 114.5%;
  text-transform: uppercase;
  color: #45035a;
}

.implementation-wrapper .content-header h1 {
  font-weight: 400;
  font-size: 32px;
  line-height: 114.5%;
  text-align: center;
  color: #2c3739;
  max-width: 451px;
}

.implementation-wrapper .content-main {
  margin-top: 50px;
}

.implementation-wrapper .content-main .workflow-step {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
}

.implementation-wrapper .content-main .workflow-step-center {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: flex-end;
}

.implementation-wrapper .content-main .details {
  display: block;

  margin-top: 30px;
}

.implementation-wrapper .content-main .details h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 114.5%;
  color: #2c3739;
  max-width: 462px;
}

.implementation-wrapper .content-main .details p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #535353;
  max-width: 341px;
}

@media screen and (max-width: 767px) {
  .implementation-wrapper .content-header span {
    font-size: 0.875rem;
  }

  .implementation-wrapper .content-header h1 {
    font-size: 1.75rem;
  }

  .implementation-wrapper .content-main .details h1 {
    font-size: 1.25rem;
  }

  .implementation-wrapper .content-main .details p {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 575px) {
  .implementation-wrapper .content-main .workflow-step {
    display: block;
  }

  .implementation-wrapper .content-main .workflow-step-center {
    display: block;
    margin: 2rem 0;
  }
}

.about-contact-us .about-contact-us-wrapper {
  background: #e9f5f7;
}
