.navbar-toggler:focus {
  outline: unset;
  border: unset;
  box-shadow: none;
}

.contact-btn {
  width: 186px;
  height: 40px;
  background: #4d61ac;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  margin-left: 100px;
}

.navigation-wrap {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 1000;
  transition: all 0.3s ease-out;
  padding: 1rem 0;
  text-align: center;
}

.navigation-wrap.scroll-on {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0.125rem 1.75rem 0 rgb(0, 0, 0, 0.09);
  transition: all 0.15s ease-in-out 0s;
  /* height: 70px; */
}

.navbar-nav {
  gap: 26px;
}

.nav-item .nav-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #535353;
  display: inline-flex;
  align-items: center;
  height: 52px;
  gap: 4px;
}

.navbar-collapse .nav-item .nav-link.active {
  color: #4d61ac;
}

@media screen and (max-width: 1399px) {
  .navbar-nav {
    gap: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .navbar-nav {
    gap: unset;
  }
}

@media screen and (max-width: 991px) {
  .navigation-wrap .navbar-collapse {
    text-align: center;
    background: #ffffff;
  }

  .contact-btn {
    margin-left: unset;
  }
  .btn-contact {
    margin-top: 8px;
  }
}
