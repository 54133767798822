.contact-wrapper {
  background: linear-gradient(
      0deg,
      rgba(3, 136, 168, 0.7),
      rgba(3, 136, 168, 0.7)
    ),
    url(../../pages/Home/bg.jpg);
}

.contact-wrapper .content-main {
  display: flex;
  justify-content: space-between;
  padding: 100px 0;
  margin: auto;
}

.contact-wrapper .content-left h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 102%;
  color: #ffffff;
  max-width: 564px;
}

.contact-wrapper .content-left p {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  opacity: 0.8;
  max-width: 347px;
  margin-top: 5px;
}

.contact-wrapper .content-left .contact-details {
  margin-top: 100px;
}

.contact-wrapper .content-left .contact-details .menu {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.contact-wrapper .content-left .contact-details .menu img {
  filter: brightness(0) invert(1);
}

.contact-wrapper .content-left .contact-details .menu li {
  margin: 15px 0;
}

.contact-wrapper .content-right {
  background: #fff;
  border-radius: 9px;
  padding: 30px;
}
.contact-wrapper .content-right form {
  display: flex;
  flex-direction: column;
}
.contact-input {
  width: 482px;
  height: 50px;
  border: 1px solid #45035a;
  border-radius: 5px;
  /* margin: 12px 0;
  padding: 10px; */
}
textarea {
  width: 482px;
  height: 128px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  resize: none;
  margin-top: 12px;
  padding: 10px;
}

.contact-wrapper .content-right h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #2c3739;
  margin: 10px 0;
}

.contact-wrapper .content-right form ::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #8d8d8d;
}

.contact-wrapper .content-right .btn-submit {
  width: 221.77px;
  height: 50px;
  background: #45035a;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  float: right;
  margin-top: 40px;
}

@media screen and (max-width: 991px) {
  .contact-us {
    height: unset;
  }
  .contact-wrapper .content-right input,
  textarea {
    width: 330px;
  }

  .contact-wrapper .content-left {
    padding: unset;
  }
}

@media screen and (max-width: 767px) {
  .contact-wrapper .content-main {
    display: block;
  }
  .contact-wrapper .content-right {
    margin-top: 30px;
  }
  .contact-wrapper .content-right input,
  textarea {
    width: unset;
  }

  .contact-wrapper .content-left h1 {
    font-size: 2rem;
  }

  .contact-wrapper .content-left p,
  .contact-wrapper .content-right form ::placeholder,
  .contact-wrapper .content-right button {
    font-size: 0.875rem;
  }

  .contact-wrapper .content-left .contact-details {
    margin-top: 50px;
  }

  .contact-wrapper .content-right h1 {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 575px) {
  .contact-wrapper .content-left {
    margin: 0 1rem;
  }

  .contact-wrapper .content-right {
    margin: 0 1rem;
  }
}
