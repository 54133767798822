.ql-editor {
  padding: unset;
}

.ql-clipboard {
  display: none;
}

.ql-container.ql-disabled .ql-tooltip {
  display: none;
}
