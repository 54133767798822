.training-home {
  margin-top: unset;
}

.training-home .training-home-wrapper {
  width: 100%;
  padding-top: 8rem;
}

.training-home-wrapper .content-header {
  display: flex;
  justify-content: center;
}

.training-home-wrapper .content-header .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 114.5%;
  color: #2c3739;
  text-align: center;
}

.training-home-wrapper .video-player {
  background-size: cover;
  border-radius: 5px;
  height: 883px;
}

.training-home-wrapper .video-info {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.training-home-wrapper .video-info .share {
  cursor: pointer;
}

.training-home-wrapper .container .video-info span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2c3739;
}

.training-home-wrapper .container .video-info h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 114.5%;
  color: #2c3739;
}

.training-home-wrapper .video-info p {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #535353;
}

@media screen and (max-width: 1199px) {
  .training-home-wrapper .video-player {
    height: 583px;
  }
}

@media screen and (max-width: 767px) {
  .training-home-wrapper .video-player {
    height: 483px;
  }

  .training-home-wrapper .content-header .heading {
    font-size: 1.75rem;
  }

  .training-home-wrapper .container .video-info span {
    font-size: 0.875rem;
  }

  .training-home-wrapper .container .video-info h1 {
    font-size: 1.25rem;
  }

  .training-home-wrapper .video-info p {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 575px) {
  .training-home-wrapper .video-player {
    height: 383px;
  }
}

.training-videos-wrapper .dropdown {
  display: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 114.5%;
  color: #53535328;
}

.training-videos-wrapper .navbar .menu {
  display: flex;
  gap: 20px;
}

.training-videos-wrapper .navbar .menu .menu-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 114.5%;
  color: #535353;
  opacity: 0.6;
}

.training-videos-wrapper .border {
  margin-bottom: 30px;
}

.training-videos-wrapper .content-main .grid-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
}

.training-videos-wrapper .content-main .grid-container .card {
  border: none;
  cursor: pointer;
}

.training-videos-wrapper .content-main .grid-container .card .card-body {
  padding: 0 1rem;
}

.training-videos-wrapper
  .content-main
  .grid-container
  .card
  .card-body
  .card-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2c3739;
  margin: 0;
}

.training-videos-wrapper
  .content-main
  .grid-container
  .card
  .card-body
  .card-text {
  margin: 0;
  margin-bottom: 0.5rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #535353;
  opacity: 0.6;
}

.training-videos-wrapper .content-main .grid-container .card .card-body small {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #090909;
  background: #dedede;
  border-radius: 20px;
  padding: 4px 10px;
}

@media screen and (max-width: 767px) {
  .training-videos-wrapper .dropdown,
  .training-videos-wrapper .navbar .menu .menu-item,
  .training-videos-wrapper
    .content-main
    .grid-container
    .card
    .card-body
    .card-title,
  .training-videos-wrapper
    .content-main
    .grid-container
    .card
    .card-body
    .card-text {
    font-size: 0.875rem;
  }

  .training-videos-wrapper
    .content-main
    .grid-container
    .card
    .card-body
    small {
    font-size: 0.813rem;
  }
}

/* @media screen and (max-width: 575px) {
  .training-videos-wrapper .content-main .grid-container {
    grid-template-columns: auto;
  }

  .training-videos-wrapper .dropdown {
    display: block;
  }

  .training-videos-wrapper .navbar {
    display: none;
  }
} */

.book-wrapper .content-main h1 {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #2c3739;
}

.book-wrapper .content-main p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #535353;
  opacity: 0.8;
}

.book-wrapper .content-main .booking-options {
  display: flex;
  gap: 20px;
}

.book-wrapper .content-main .booking-options button {
  background: #ffffff;
  border: 1px solid #45035a;
  border-radius: 5px;
  width: 150px;
}

.book-wrapper .content-main .booking-options input {
  width: 260px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #2c3739;
  opacity: 0.6;
}

.book-wrapper .content-main .contact-details {
  margin-top: 32px;
}

.book-wrapper .content-main .contact-details .heading,
.number,
.email {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2c3739;
  margin-right: 20px;
}

@media screen and (max-width: 1199px) {
  .book-wrapper .content-main .booking-options input {
    width: 200px;
  }
}

@media screen and (max-width: 991px) {
  .book-wrapper .content-main {
    margin-top: 2rem;
  }
  .book-wrapper .content-main .booking-options input {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .book-wrapper .content-main .contact-details .heading,
  .number,
  .email {
    font-size: 0.875rem;
  }

  .book-wrapper .content-main h1 {
    font-size: 1.75rem;
  }

  .book-wrapper .content-main .booking-options input,
  .book-wrapper .content-main p {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 575px) {
  .book-wrapper .content-main .booking-options {
    flex-direction: column;
    align-items: flex-start;
  }
  .book-wrapper .content-main .booking-options .dropdown {
    margin-bottom: 20px;
  }
}

.training-testimonials-wrapper .content-header span {
  font-weight: 500;
  font-size: 16px;
  line-height: 114.5%;
  text-transform: uppercase;
  color: #45035a;
}

.training-testimonials-wrapper .content-header .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 114.5%;
  color: #2c3739;
}

.training-testimonials-wrapper .content-bottom .quote-left {
  padding: 20px;
  max-width: 944px;
  background: #ffffff;
  border: 0.1px solid #c5c5c5;
  border-radius: 2px;
}

.training-testimonials-wrapper .content-bottom {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.training-testimonials-wrapper .content-bottom .quote-right {
  padding: 20px;
  max-width: 944px;
  background: #ffffff;
  border: 0.1px solid #c5c5c5;
  border-radius: 2px;
}

.training-testimonials-wrapper .content-bottom .subheading {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #535353;
  margin-left: 50px;
}

.training-testimonials-wrapper .content-bottom .profile {
  margin-left: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.training-testimonials-wrapper .content-bottom .profile .name {
  font-weight: 500;
  font-size: 18px;
  line-height: 114.5%;
  color: #2c3739;
}

.training-testimonials-wrapper .content-bottom .profile .position {
  font-weight: 400;
  font-size: 16px;
  line-height: 114.5%;
  color: #535356;
}

@media screen and (max-width: 767px) {
  .training-testimonials-wrapper .content-bottom {
    display: block;
  }
  .training-testimonials-wrapper .content-bottom .quote-left {
    margin-bottom: 0.75rem;
  }

  .training-testimonials-wrapper .content-bottom .profile .position,
  .training-testimonials-wrapper .content-bottom .subheading,
  .training-testimonials-wrapper .content-header span {
    font-size: 0.875rem;
  }

  .training-testimonials-wrapper .content-header .heading {
    font-size: 1.75rem;
  }

  .training-testimonials-wrapper .content-bottom .profile .name {
    font-size: 1rem;
  }
}
