.modal-content {
  border: none;
  width: fit-content;
  display: flex;
  margin: 0 auto;
  background: transparent;
}

.popup {
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  background-color: rgba(255, 253, 253, 1); /* set around 0.3 for glass effect*/
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);

  backdrop-filter: blur(15px);
}

.pop-image {
  height: auto;
  width: 50vw;
  padding: 0 2rem 2rem 2rem;
}
.btn-close {
  float: right;
  padding: 1rem;
}

.pop-title{
  padding: 1rem;
  margin-left: 1rem;
  font-size: 18px;
  font-weight: 500;
  color: rgb(36, 36, 29);
}
@media screen and (max-width: 1199px) {
  .pop-image {
    height: auto;
    width: 80vw;
    padding: 0 2rem 2rem 2rem;
  }
}

@media screen and (max-width: 991px) {
  .pop-image {
    height: auto;
    width: 100vw;
    padding: 0 2rem 2rem 2rem;
  }
}