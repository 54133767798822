.careers {
  margin-top: unset;
}

.careers .careers-wrapper {
  width: 100%;
  padding-top: 8rem;
}

.careers-wrapper .content-top {
  display: flex;
  justify-content: center;
}

.careers-wrapper .content-top .content h1 {
  font-weight: 400;
  font-size: 32px;
  line-height: 114.5%;
  color: #2c3739;
  max-width: 451px;
}

.careers-wrapper .content-top .content input {
  background: #f7f7f7;
  border-radius: 5px;
  width: 406px;
  height: 50px;
  margin-top: 20px;
  border: none;
}

.careers-wrapper .content-main .card {
  border: none;
  padding: 1rem 0;
}

.careers-wrapper .content-main .card .card-body {
}

.careers-wrapper .content-main .card .card-body .card-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #2c3739;
  margin: 0;
}

.careers-wrapper .content-main .card .card-body .card-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #535353;
  margin: 0;
}

.careers .careers-wrapper .col-md-4 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.careers-wrapper .col-md-4 button {
  width: 216px;
  height: 50px;
  background: #45035a;
  border-radius: 5px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border: none;
}

.careers-wrapper .navbar .menu {
  display: flex;
  gap: 20px;
}

.careers-wrapper .navbar .menu .menu-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 114.5%;
  color: #535353;
  opacity: 0.6;
  cursor: pointer;
}

.careers-wrapper .navbar .menu .menu-item.active {
  color: #4d61ac;
}

@media screen and (max-width: 767px) {
  .careers-wrapper .content-top .content h1 {
    font-size: 1.75rem;
  }

  .careers-wrapper .navbar .menu .menu-item,
  .careers-wrapper .content-main .card .card-body .card-text,
  .careers-wrapper .col-md-4 button {
    font-size: 0.875rem;
  }

  .careers-wrapper .content-main .card .card-body .card-title {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 575px) {
  .careers-wrapper .content-top .content input {
    width: unset;
  }
}

.controlInput1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
