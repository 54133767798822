.home {
  margin-top: unset;
}

.home .home-wrapper {
  width: 100%;
  padding-top: 8rem;
}

.home-wrapper .home-banner {
  display: flex;
  justify-content: space-between;
}

.home-wrapper .container .content-left {
  margin-top: 100px;
}

.home-wrapper .content-left .heading {
  font-weight: 500;
  font-size: 48px;
  color: #3c3c3c;
}

.home-wrapper .content-left .subheading {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #535353;
  margin-top: 10px;
}

.home-wrapper .content-left .btn-group {
  align-items: baseline;
}

.home .content-left .btn-group .btn-contact {
  width: 186px;
  height: 40px;
  background: #4d61ac;
  border-radius: 5px;
  border: none;

  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}

.home .content-left .btn-group .btn-see-more {
  width: 186px;
  height: 40px;
  border-radius: 5px;
  background-color: #ffffff;
  border: none;

  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #4d61ac;
}

.home-wrapper .content-bottom {
  background: #fafafa;
}

.home-wrapper .content-bottom .content {
  display: block;
  padding: 20px 10px;
}

.home-wrapper .content-bottom .content .heading {
  margin: auto;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #2c3739;
}

.home-wrapper .content-bottom .content .partners {
  margin-top: 10px;
  display: flex;
  gap: 1rem;
}

.home-wrapper .content-bottom .content .partners img {
  height: 127px;
  object-fit: contain;
}

@media screen and (max-width: 1199px) {
  .home-wrapper .content-left .btn-group {
    display: block;
    margin-top: 2rem;
    align-items: flex-start;
  }

  .home-wrapper .content-left .btn-group .btn-see-more {
    text-align: unset;
  }
}

@media screen and (max-width: 991px) {
  .home-wrapper .content-bottom .content .partners {
    flex-direction: column;
  }

  .home-wrapper .content-bottom .content .partners img {
    height: unset;
  }

  .home-wrapper .content-left .heading {
    font-size: 2.5rem;
  }

  .home-wrapper .content-left .subheading {
    font-size: 14px;
  }

  .home-wrapper .container .content-left {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) {
  .home-wrapper .content-left {
    margin-top: unset;
  }

  .home-wrapper .content-left .btn-group .btn-see-more {
    text-align: center;
  }

  .home-wrapper .content-left .heading {
    font-size: 2.5rem;
  }

  .home-wrapper .content-bottom .content .heading {
    font-size: 1rem;
  }

  .home-wrapper .content-left .subheading,
  .home .content-left .btn-group .btn-contact,
  .home .content-left .btn-group .btn-see-more {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media screen and (max-width: 575px) {
  .home-wrapper .content-left .heading {
    font-size: 1.75rem;
  }
}

.what-we-do-wrapper .content-header {
  display: flex;
  justify-content: center;
}

.what-we-do-wrapper .content-header h1 {
  max-width: 451px;
  text-align: center;

  font-weight: 400;
  font-size: 32px;
  line-height: 114.5%;
  color: #2c3739;
}

.what-we-do-wrapper .card-group {
  gap: 1rem;
  margin-top: 2rem;
}

.what-we-do-wrapper .card-group .card {
  padding: 2rem;
  border: 0.5px solid #c5c5c5;
  border-radius: 2px;
}

.what-we-do-wrapper .card .card-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #2c3739;
}

.what-we-do-wrapper .card .card-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #535353;
}

.what-we-do-wrapper .card .card-body {
  padding: 1rem 0;
}

@media screen and (max-width: 767px) {
  .what-we-do-wrapper .content-header h1 {
    font-size: 1.75rem;
  }

  .what-we-do-wrapper .card .card-title {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .what-we-do-wrapper .card .card-text {
    font-size: 0.875rem;
  }
}

.testimonials {
  background: #d5f6f1;
}

.testimonials .testimonials-wrapper {
  margin: auto;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
}

.testimonials-wrapper .content-left span {
  font-weight: 500;
  font-size: 16px;
  line-height: 114.5%;
  text-transform: uppercase;
  color: #45035a;
}

.testimonials-wrapper .content-left .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 114.5%;
  color: #45035a;
}

.testimonials-wrapper .content-left .subheading {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #535353;
  margin-top: 10px;
}

.testimonials-wrapper .content-right .quote-top {
  padding: 20px;
  max-width: 944px;
  background: #ffffff;
  border: 0.1px solid #c5c5c5;
  border-radius: 2px;

  margin-bottom: 1rem;
}

.testimonials-wrapper .content-right .quote-bottom {
  padding: 20px;
  max-width: 944px;
  background: #ffffff;
  border: 0.1px solid #c5c5c5;
  border-radius: 2px;

  margin-left: 30px;
  margin-top: 30px;
}

.testimonials-wrapper .content-right .subheading {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #535353;
  margin-left: 50px;
}

.testimonials-wrapper .content-right .profile {
  margin-left: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.testimonials-wrapper .content-right .profile .name {
  font-weight: 500;
  font-size: 18px;
  line-height: 114.5%;
  color: #2c3739;
}

.testimonials-wrapper .content-right .profile .position {
  font-weight: 400;
  font-size: 16px;
  line-height: 114.5%;
  color: #535356;
}

@media screen and (max-width: 991px) {
  .testimonials .testimonials-wrapper {
    display: block;
  }

  .testimonials-wrapper .content-right .quote-bottom {
    margin-left: unset;
  }

  .testimonials-wrapper .content-left {
    text-align: center;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .testimonials-wrapper .content-right .profile .position,
  .testimonials-wrapper .content-left .subheading,
  .testimonials-wrapper .content-left span,
  .testimonials-wrapper .content-right .subheading {
    font-size: 0.875rem;
  }

  .testimonials-wrapper .content-left .heading {
    font-size: 1.75rem;
  }

  .testimonials-wrapper .content-right .profile .name {
    font-size: 1rem;
  }
}

@media screen and (max-width: 575px) {
  .testimonials .testimonials-wrapper {
    padding: 30px 10px;
  }
}

.usecase .usecase-wrapper {
  display: flex;
  justify-content: space-between;
}

.usecase-wrapper .content-left span {
  font-weight: 500;
  font-size: 16px;
  line-height: 114.5%;
  text-transform: uppercase;
  color: #45035a;
}

.usecase-wrapper .content-left .heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 114.5%;
  color: #2c3739;
}

.usecase-wrapper .content-left .subheading {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #535353;
  margin-bottom: 50px;
}

.usecase-wrapper .content-left .btn-contact {
  width: 186px;
  height: 40px;
  background: #4d61ac;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}

.usecase-wrapper .content-right .menu {
  display: flex;
  gap: 20px;
}

.usecase-wrapper .content-right .menu .menu-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 114.5%;
  color: #535353;
  cursor: pointer;
}

.usecase-wrapper .content-right .menu .menu-item.active {
  color: #4d61ac;
}

.usecase-wrapper .content-right .border {
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 991px) {
  .usecase .usecase-wrapper {
    display: block;
  }
  .usecase-wrapper .content-right {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .usecase-wrapper .content-left span,
  .usecase-wrapper .content-left .subheading,
  .usecase-wrapper .content-right .menu .menu-item,
  .usecase-wrapper .content-left .btn-contact {
    font-size: 0.875rem;
  }

  .usecase-wrapper .content-left .heading {
    font-size: 1.75rem;
  }
}

.features-wrapper .content-header {
  display: flex;
  justify-content: center;
  text-align: center;
}

.features-wrapper .content-header .heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 114.5%;
  text-align: center;
  text-transform: uppercase;
  color: #45035a;
}

.features-wrapper .content-header h1 {
  font-weight: 400;
  font-size: 32px;
  line-height: 114.5%;
  text-align: center;
  color: #2c3739;
}

.features-wrapper .content-main {
  margin-top: 40px;
}

.features-wrapper .content-main .card-group {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 0.2rem;
}

.features-wrapper .content-main .card {
  background: #e9f5f7;
  border: none;
  border-radius: unset;
}

.features-wrapper .content-main .card .card-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #2c3739;
}

.features-wrapper .content-main .card .card-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #535353;
}

.features-wrapper .content-main .card:hover {
  background: #45035a;
  cursor: pointer;
}

.features-wrapper .content-main .card:hover .card-title {
  color: #ffffff;
}

.features-wrapper .content-main .card:hover .card-text {
  color: #ffffff;
  opacity: 0.7;
}

.features-wrapper .content-main .card .card-body {
  padding: 50px;
}

@media screen and (max-width: 991px) {
  .features-wrapper .content-main .card .card-body {
    padding: 30px;
  }
}

@media screen and (max-width: 767px) {
  .features-wrapper .content-main .card-group {
    grid-template-columns: auto auto;
  }

  .features-wrapper .content-main .card .card-body {
    padding: 40px;
  }

  .features-wrapper .content-main .card {
    height: unset;
  }

  .features-wrapper .content-header .heading,
  .features-wrapper .content-main .card .card-text {
    font-size: 0.875rem;
  }

  .features-wrapper .content-header h1 {
    font-size: 1.75rem;
  }

  .features-wrapper .content-main .card .card-title {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 575px) {
  .features-wrapper .content-main .card-group {
    display: block;
  }
}

.features-banner {
  background: linear-gradient(
    0deg,
    rgba(0, 79, 98, 0.95),
    rgba(0, 79, 98, 0.95)
  );
}

.features-banner .features-banner-wrapper {
  margin: auto;
  padding: 100px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.features-banner-wrapper .content-left .heading {
  font-weight: 500;
  font-size: 36px;
  line-height: 114.5%;
  color: #ffffff;
}

.features-banner-wrapper .content-left .subheading {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  opacity: 0.8;
  max-width: 395px;
}

.features-banner-wrapper .content-center {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.features-banner-wrapper .content-center .content {
  border-right: 1px solid rgb(201, 200, 200);
}

.features-banner-wrapper .content-center h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
  margin-right: 20px;
}

.features-banner-wrapper .content-center span {
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
}

.features-banner-wrapper .content-right .btn-contact {
  width: 276px;
  height: 50px;
  background: #45035a;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}

@media screen and (max-width: 991px) {
  .features-banner .features-banner-wrapper {
    display: block;
    text-align: center;
  }

  .features-banner-wrapper .content-center {
    margin: 2rem 0;
    gap: unset;
  }

  .features-banner-wrapper .content-left .subheading {
    max-width: unset;
  }
}

@media screen and (max-width: 767px) {
  .features-banner-wrapper .content-left .heading {
    font-size: 1.75rem;
  }

  .features-banner-wrapper .content-center h1 {
    font-size: 2rem;
  }

  .features-banner-wrapper .content-left .subheading,
  .features-banner-wrapper .content-center span,
  .features-banner-wrapper .content-right .btn-contact {
    font-size: 0.875rem;
  }
}

.training-wrapper .content-header {
  display: flex;
  justify-content: center;
  text-align: center;
}

.training-wrapper .content-header h1 {
  font-weight: 400;
  font-size: 32px;
  line-height: 114.5%;
  color: #2c3739;
}

.training-wrapper .content-header p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #535353;
  opacity: 0.8;
  margin-top: 10px;
}

.training-wrapper .card-group {
  gap: 1rem;
  margin-top: 2rem;
}

.training-wrapper .card-group .card {
  background: #ffffff;
  border: 0.5px solid #c5c5c5;
  border-radius: 2px;

  cursor: pointer;
}

.training-wrapper .card-group .card .card-body {
  padding: 1rem 1rem;
  text-align: start;
}

.training-wrapper .card-group .card .card-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #2c3739;
  margin-bottom: unset;
}

.training-wrapper .card-group .card .card-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #535353;
  margin-top: unset;
}

.training-wrapper .btn-see-all {
  background: #fff1e8;
  height: 43px;
  width: 184px;
  border: none;
  border-radius: 2px;

  font-weight: 600;
  font-size: 16px;
  line-height: 114.5%;
  text-align: center;
  color: #4d61ac;
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  .training-wrapper .content-header h1 {
    font-size: 1.75rem;
  }

  .training-wrapper .content-header p,
  .training-wrapper .card-group .card .card-text,
  .training-wrapper .btn-see-all {
    font-size: 0.875rem;
  }

  .training-wrapper .card-group .card .card-title {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
  }
}

.home-blogs .home-blogs-wrapper {
  margin: auto;
  margin-bottom: 100px;
}

.home-blogs-wrapper .content-top {
  display: flex;
  justify-content: space-between;
}

.home-blogs-wrapper .content-left {
  line-height: 114.5%;
}

.home-blogs-wrapper .content-left span {
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: #45035a;
}

.home-blogs-wrapper .content-left h1 {
  font-weight: 400;
  font-size: 32px;
  color: #2c3739;
}

.home-blogs-wrapper .content-left p {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #535353;
}

/* .home-blogs-wrapper .content-left button {
  background: #fff1e8;
  height: 43px;
  width: 184px;
  border: none;
  border-radius: 2px;

  font-weight: 600;
  font-size: 16px;
  line-height: 114.5%;
  text-align: center;
  color: #4d61ac;
  margin-top: 40px;
} */

.home-blogs-wrapper .content-top .content-right .card {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 18px;
  display: flex;
  justify-content: space-between;
}

.card-body {
  padding: unset;
}

.home-blogs-wrapper .card .card-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 169.5%;
  color: #535353;
  opacity: 0.77;
}

.home-blogs-wrapper .card small {
  background: #ececec;
  opacity: 0.8;
  border-radius: 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #2c3739;
  padding: 4px 10px;
}

.home-blogs-wrapper .card .tags {
  display: flex;
  flex-wrap: wrap;
}

.home-blogs-wrapper .card .card-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 135.5%;
  color: #2c3739;
  margin: unset;
}

.home-blogs-wrapper .card-group .card {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 1rem;
}

.home-blogs-wrapper .card-group {
  gap: 1rem;
}

@media screen and (max-width: 991px) {
  .home-blogs-wrapper .content-top {
    display: block;
  }

  .home-blogs-wrapper .content-left {
    margin-bottom: 2rem;
  }

  .home-blogs-wrapper .content-left span,
  .home-blogs-wrapper .content-left p,
  .home-blogs-wrapper .content-left button,
  .home-blogs-wrapper .card .card-text {
    font-size: 0.875rem;
  }

  .home-blogs-wrapper .content-left h1 {
    font-size: 1.75rem;
  }

  .home-blogs-wrapper .card small {
    font-size: 0.813rem;
  }

  .home-blogs-wrapper .card .card-title {
    font-size: 1.125rem;
  }

  .home-blogs-wrapper .card-group {
    display: block;
    margin-top: 0;
  }

  .home-blogs-wrapper .card-group .card {
    margin: 1rem 0;
  }
}

@media screen and (max-width: 767px) {
  .home-blogs-wrapper .card .tags {
    margin-top: 0.5rem;
  }
}
.slick-slide{
  padding-right:0px !important;
}