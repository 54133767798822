.footer {
  /* background: #0a5568; */
  /* margin-top: 100px; */
}

.footer .footer-wrapper {
  margin: auto;
}

.footer-wrapper .content-main {
  display: flex;
  justify-content: space-between;
  padding: 75px 0;
}

.footer-wrapper .content-main h1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 169.5%;
  color: #ffffff;
}

.footer-wrapper .content-main li,
span {
  font-weight: 400;
  font-size: 16px;
  line-height: 169.5%;
  color: #ffffff;
  margin: 10px 0;
}

.footer-wrapper .content-main .content-left {
  max-width: 342px;
}

.footer-wrapper .content-main .content-left input {
  width: 341px;
  height: 50px;
  background: #ffffff;
  border-radius: 5px;
}

.footer .content-bottom {
  display: flex;
  justify-content: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.06);
  padding: 30px 0;
}

.footer .content-left .subheading {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  opacity: 0.8;
}

.footer .content-bottom .content .subheading {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  margin-top: 1rem;
}

.footer .content-bottom p {
  max-width: 670px;
}

.footer .content-bottom img {
  filter: brightness(0) invert(1);
}

@media screen and (max-width: 991px) {
  .footer {
    height: unset;
  }

  .footer-wrapper .content-main {
    display: block;
  }

  .footer-wrapper .content-main .content-center {
    margin: 2rem 0;
  }

  .footer-wrapper .content-main .content-left input {
    width: unset;
  }
}

@media screen and (max-width: 575px) {
  .footer-wrapper .content-main h1 {
    font-size: 0.875rem;
  }

  .footer-wrapper .content-main li,
  span,
  .subheading {
    font-size: 0.875rem;
  }
}
