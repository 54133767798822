.top-banner {
  width: 100%;
  margin-top: unset;
  padding-top: 8rem;
}

.top-banner-wrapper {
  background: url(../Blog-Detail/intro-bg.jpg) no-repeat;
  background-size: 100% 599px;
  border-radius: 5px;
}

.blog-detail {
  margin-top: unset;
}

.blog-detail .blog-detail-banner {
  margin-top: 50px;
  border: 0.5px solid gray;
}

.blog-detail .content-bottom {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 30px;
}

.blog-detail .content-main {
  padding: 50px;
}

.blog-detail .content-main .share {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.blog-detail .content-bottom h1 {
  font-weight: 500;
  font-size: 32px;
  line-height: 114.5%;
  color: #2c3739;
}

.blog-detail .content-bottom p {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #535353;
}

.blog-detail .content-bottom span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2c3739;
  margin-left: 10px;
}

.blog-detail .content-bottom .content-description {
  /* text-align: justify; */
  padding: 0 200px;
}

@media screen and (max-width: 1399px) {
  .top-banner-wrapper {
    background-size: 100% 550px;
  }

  .blog-detail .content-bottom .content-description {
    /* text-align: justify; */
    padding: 0 150px;
  }
}

@media screen and (max-width: 1199px) {
  .top-banner-wrapper {
    background-size: 100% 450px;
  }
  .blog-detail .content-bottom .content-description {
    /* text-align: justify; */
    padding: 0 80px;
  }
}

@media screen and (max-width: 991px) {
  .top-banner-wrapper {
    background-size: 100% 399px;
  }
  .blog-detail .content-main {
    padding: 50px 0;
  }
  .blog-detail .content-bottom .content-description {
    /* text-align: justify; */
    padding: 0 50px;
  }
}

@media screen and (max-width: 767px) {
  .top-banner-wrapper {
    background-size: 100% 299px;
  }
  .blog-detail .content-bottom .content-description {
    /* text-align: justify; */
    padding: 0 20px;
  }

  .blog-detail .content-bottom h1 {
    font-size: 24px;
  }

  .blog-detail .content-bottom p {
    font-size: 14px;
  }

  .blog-detail .content-bottom span {
    font-size: 14px;
  }
}

@media screen and (max-width: 575px) {
  .top-banner-wrapper {
    background-size: 100% 199px;
  }
  .blog-detail .content-bottom .content-description {
    /* text-align: justify; */
    padding: 0 20px;
  }
}
