.home {
  margin-top: unset;
}
.home-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
  width: 100%;
  padding-top: 8rem;
}

.home-wrapper .content-right {
  padding: 6px;
}

.home-wrapper .content-right .card-img {
  width: 100%;
  height: 174px;
  object-fit: contain;
}

.home-wrapper .card-img-overlay {
  top: unset;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.81) 100%
  );
}

.home-wrapper .card-img-overlay p {
  font-weight: 500;
  font-size: 20px;
  line-height: 114%;
  color: #ffffff;
}

.home-wrapper .content-left .card {
  cursor: pointer;
}

.home-wrapper .content-left .card .card-img {
  height: 546px;
}

.home-wrapper .content-right .card {
  margin: 10px 0;
  cursor: pointer;
}

@media screen and (max-width: 1399px) {
  .home-wrapper .content-right .card-img {
    height: 149px;
  }

  .home-wrapper .content-left .card .card-img {
    height: 472px;
  }
}

@media screen and (max-width: 1199px) {
  .home-wrapper .content-right .card-img {
    height: 123px;
  }

  .home-wrapper .content-left .card .card-img {
    height: 394px;
  }
}

@media screen and (max-width: 991px) {
  .home-wrapper {
    display: block;
  }

  .home-wrapper .content-right {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: unset;
  }

  .home-wrapper .content-right .card-img {
    height: 174px;
  }

  .home-wrapper .content-right .card {
    width: 231px;
  }
}

@media screen and (max-width: 767px) {
  .home-wrapper .content-right {
    display: unset;
  }

  .home-wrapper .content-left .card .card-img {
    height: unset;
  }
  .home-wrapper .content-right .card-img {
    height: unset;
  }

  .home-wrapper .content-left {
    margin-bottom: 10px;
  }

  .home-wrapper .content-right .card {
    width: unset;
  }

  .home-wrapper .card-img-overlay p {
    font-size: 1.125rem;
  }
}

.newsletter {
  background: linear-gradient(92.48deg, #ed6112 -5.01%, #ff9457 100.43%);
}

.newsletter-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
}

.newsletter-wrapper h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #ffffff;
}

.newsletter-wrapper p {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  max-width: 616px;
}

.newsletter-wrapper input {
  width: 375px;
  height: 50px;
  margin: 10px 0;

  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #8d8d8d;
}

@media screen and (max-width: 767px) {
  .newsletter-wrapper h1 {
    font-size: 2rem;
  }

  .newsletter-wrapper p {
    font-size: 1.125rem;
  }

  .newsletter-wrapper input {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 575px) {
  .newsletter-wrapper input {
    width: unset;
  }
}

.blogs-wrapper .navbar .menu {
  display: flex;
  gap: 20px;
}

.blogs-wrapper .navbar .menu .menu-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 114.5%;
  color: #535353;
  opacity: 0.6;
}

.blogs-wrapper .content-bottom .card {
  cursor: pointer;
}

.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}

.blogs-wrapper .content-bottom .card .card-body {
  padding: 1rem 1rem;
}

.blogs-wrapper .content-bottom .card img {
  padding: 1rem 1rem 0 1rem;
}

.blogs-wrapper .content-bottom .card .card-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 135.5%;
  color: #2c3739;
}

.blogs-wrapper .content-bottom .card .card-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #535353;
  opacity: 0.77;
}

.blogs-wrapper .content-bottom .card .tags {
  display: flex;
  flex-wrap: wrap;
}

.blogs-wrapper .content-bottom .card small {
  background: #ececec;
  border-radius: 20px;
  padding: 4px 10px;
  color: #2c3739;
  height: fit-content;
  width: max-content;
}

@media screen and (max-width: 767px) {
  .blogs-wrapper .content-bottom .card .card-title {
    font-size: 1.125rem;
  }

  .card-img-top {
    height: unset;
    object-fit: unset;
  }

  .blogs-wrapper .navbar .menu .menu-item,
  .blogs-wrapper .content-bottom .card .card-text {
    font-size: 0.875rem;
  }
}
