@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Poppins:wght@400;500&display=swap");

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
}

:active,
:hover,
:focus {
  outline: 0 !important;
  outline-offset: 0;
}

a,
a:hover {
  text-decoration: none;
  color: black;
}

.footer_icon:hover {
  color: #4d61ac !important;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

section {
  margin-top: 100px;
}

html {
  scroll-behavior: smooth;
}

#main {
  height: 100vh;
}
