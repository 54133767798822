@tailwind base;
@tailwind components;
@tailwind utilities;

button {
  @apply tw-px-4 tw-py-4 md:tw-px-8 md:tw-py-3;
}

@layer components {
  .div-wrapper {
    margin-top: unset;
    width: 100%;
    padding-top: 6rem;
  }
  .max-container {
    max-width: 1248px;
    margin: 0 auto;
  }

  .testimonial-pagination {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }
  .testimonial-bullet {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: red;
  }
  .testimonial-bullet-active {
    background-color: green !important;
  }
  .gradient-background {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    );
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .list-disc {
    list-style-type: disc;
  }
  /* .slider-container {
    margin: 0 20px !important;
  } */
  .button__bar {
    display: inline-block;
    vertical-align: middle;
    margin: auto;
    text-align: center;
  }
  .button__bar li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    margin: 0 3px;
    padding: 0;
  }
  .button__bar li button {
    background-color: #c9c9c9;
    border: none;
    color: transparent;
    cursor: pointer;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    padding: 0;
  }
  .button__bar li.slick-active button {
    background-color: #4d61ac;
    width: 8px;
    height: 8px;
  }
  .testimonial-slider {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
  }
  .line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@layer utilities {
  .heading-text {
    @apply tw-text-[46px] md:tw-text-6xl tw-font-bold;
  }
  .top-title {
    @apply tw-text-[#45035a] tw-text-[18px] tw-leading-6 tw-uppercase tw-font-medium;
  }
  .title {
    @apply tw-text-4xl tw-font-medium;
  }
  /* .btn-orange {
    @apply tw-bg-[#4d61ac] hover:tw-bg-[#FD9053] tw-transition tw-ease-in-out tw-rounded-md tw-text-white-500 tw-text-lg;
  } */
  .btn-orange {
    @apply tw-bg-[#4d61ac] tw-transition tw-ease-in-out tw-rounded-md tw-text-white-500 tw-text-lg tw-border-none hover:tw-bg-[#fff1e8] hover:tw-text-[#4d61ac];
  }
  .padding-x {
    @apply tw-px-6 lg:tw-px-0;
  }
  .top-title-orange {
    @apply tw-text-[#4d61ac] tw-text-[18px] md:tw-text-xl tw-uppercase tw-font-medium;
  }
  .title-hero {
    @apply tw-text-[#3C3B3B] md:tw-max-w-2xl tw-pt-2 tw-pb-5 heading-text;
  }
  .title-desc-hero {
    @apply tw-text-[#787878] md:tw-max-w-xl tw-text-lg;
  }
  .hero-div {
    @apply tw-w-full tw-flex tw-flex-col tw-justify-center tw-py-24;
  }
  .hero-div-sm {
    @apply tw-w-full tw-flex tw-flex-col tw-justify-center tw-py-12;
  }
  .text-desc {
    @apply tw-text-lg tw-text-[#787878];
  }
  .tab-active {
    @apply tw-text-[#FF813A] tw-border-b tw-border-b-[#FF813A] !important;
  }
  .tab-active-hover {
    @apply focus:tw-text-[#FF813A] hover:tw-text-[#FF813A] tw-border tw-border-transparent focus:tw-border-b-[#FF813A] hover:tw-border-b-[#FF813A];
  }
  .pill-orange {
    @apply tw-bg-[#FFEEE4] tw-text-[#4d61ac] tw-px-4 tw-py-1 tw-rounded-[30px] tw-text-sm md:tw-text-base;
  }
  .career-title {
    @apply tw-text-[20px] md:tw-text-2xl tw-font-medium tw-capitalize;
  }
  .career-bold-title {
    @apply tw-text-[20px] md:tw-text-2xl tw-font-semibold tw-capitalize;
  }
  .career-input {
    @apply tw-rounded-md tw-border tw-border-[#C3C3C3] tw-w-full tw-py-3 tw-px-6 tw-text-gray-700 tw-leading-6 
    tw-outline-none focus:tw-outline-none focus:tw-border-[#45035a] hover:tw-border-[#45035a];
  }
  .portfolio-slick-slide {
    @apply tw-pr-0 tw-pb-8 md:tw-pb-[64px] lg:tw-pr-[34px];
  }
  .testimonial-pagination {
    @apply tw-mt-8 lg:tw-mt-12;
  }
  .button-transition {
    @apply tw-transition tw-ease-in-out;
  }
  .icon-portfolio {
    @apply tw-flex tw-px-3 tw-py-1 tw-gap-1 md:tw-gap-2 tw-items-center tw-rounded-md md:tw-px-6 md:tw-py-2 tw-h-[38px];
  }
  .about-desc {
    @apply tw-text-base tw-pt-4 tw-text-[#787878];
  }
  .benefit-title {
    @apply tw-text-2xl tw-font-medium tw-py-2.5;
  }
  .btn-career {
    @apply tw-px-8 tw-py-3;
  }
  .btn-contact {
    @apply tw-px-3 tw-py-2 tw-text-base;
  }
  .btn-blogs {
    @apply tw-text-base tw-font-semibold tw-mt-10;
  }
}
.slick-slide {
  @apply tw-mr-0 sm:tw-pr-[24px];
}
.about-description {
  @apply tw-text-[#efe0e0] md:tw-max-w-xl tw-text-lg;
}