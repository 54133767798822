.hero_banner {
  /* padding: 100px 0px; */
  color: white;
  height: 600px;
  /* background-image: url("../../../public/assets/images/epark.png"); */
  /* The image used */
  /* background-color: #cccccc; */
  /* Used if the image is unavailable */
  /* height: 500px; */
  /* You must set a specified height */
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  border-radius: 5px;
}

.hero-title {
  font-weight: 600;
  font-size: 50px;
  line-height: 75px;
  color: #ffffff;
}

.hero-sub {
  font-weight: 500;
  font-size: 20px !important;
  line-height: 30px;
}

.hero-des {
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  color: #e6e6e6;
  margin-top: 40px;
}

.section-des {
  margin-top: 100px;
}

.btn-pri {
  border: none;
  border-radius: 5px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  width: 186px !important;
  background: #4d61ac;
  color: #fff;
  padding: 0.5rem;
  text-align: center;
}

.btn-sec {
  border: none;
  border-radius: 5px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 40px;
  line-height: 150%;
  width: 186px !important;
  background: transparent;
  color: #fff;
  display: inline-block;
}

.btn-pri:hover {
  background: white;
}

.crd {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
}

.crd-blue {
  background: #e9f5f7;
  border-radius: 15px;
  padding: 30px;
  margin-top: 60px;
}

.crd-h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 52px;

  color: #293241;
}

.crd-des {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  color: #747d8c;
}

.crd-feature {
  position: relative;
  margin-left: 100px;
  margin-bottom: 20px;
}

.fea-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.fea-des {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #747d8c;
}

.fea-icon {
  position: absolute;
  top: 0;
  left: -50px;
  width: 25px;
  height: auto;
}

.accordion-item {
  margin-bottom: 20px;
  border: 1.5px solid rgba(255, 111, 30, 0.5) !important;
}

.accordion-button {
  color: #4d61ac !important;
  background-color: white !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 18px !important;
  color: #4d61ac;
}

.accordion-button:focus {
  box-shadow: none;
  color: #4d61ac;
}

@media screen and (max-width: 767px) {
  .hero-title {
    font-size: 42px;
    line-height: 65px;
  }

  .hero-sub {
    font-size: 18px;
  }

  .hero-des,
  .hero-des p {
    font-size: 20px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 575px) {
  .hero-title {
    font-size: 36px;
    line-height: 55px;
  }

  .hero-sub {
    font-size: 16px !important;
  }

  .hero-des,
  .hero-des p {
    font-size: 18px;
    line-height: 30px;
  }
}

@media screen and (max-width: 375px) {
  .hero-title {
    font-size: 30px;
    line-height: 45px;
  }

  .hero-sub {
    font-size: 14px !important;
  }

  .hero-des,
  .hero-des p {
    font-size: 16px;
    line-height: 25px;
    margin-top: unset;
  }

  .crd-feature {
    margin-left: 50px;
  }
}

.ql-editor {
  padding: unset;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #363a41;
}

.ql-toolbar.ql-snow {
  display: none;
}

.ql-clipboard {
  display: none;
}
