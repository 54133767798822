.sticky-image {
    /* Ensure it's positioned relative to the container */
    position: sticky;
    /* Adjust top value as needed */
    top: 225px; /* or any value that suits your layout */
    /* Limit height to prevent overflowing */
    max-height: calc(100vh - 100px); /* Example: 100vh minus some offset */
    overflow-y: auto; /* Add scroll if content exceeds max height */
  }
  
  @media (max-width: 768) {
    /* Disable sticky behavior on smaller screens */
    .sticky-image {
      position: static; /* or revert to default positioning */
      max-height: none; /* Reset max height */
      overflow-y: visible; /* Reset overflow behavior */
    }
  }
  